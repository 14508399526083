import {
  FirestoreQuery,
  FirestoreQueryItem,
  getCollectionsForCollective,
  getCollectiveByShortName,
  getLiveTransactionsAfterDate,
  getMintPhaseAllowLists,
  getMintPhaseDiscounts,
  getMintPhases,
  getProject,
  getProjectListener,
  getProjectWithDynamicMapping,
  getProjectsForNextBatch,
  getRelatedProjects,
  getSuperCubProjects,
} from '#lib/firestore';
import {
  LiveMintAllowStakeAddresses,
  LiveTransaction,
  MintPhase,
  MintPhaseDiscountPolicy,
  Project,
} from '#types/index';
import React from 'react';
import { useEffect, useState } from 'react';

export type ProjectItem = Project & {
  groupKey?: string;
  key: number;
};

const LIMIT = 24;

type UseProjectItemsProps = {
  query?: FirestoreQuery;
  text?: string;
};

export const useProjectItems = ({ query }: UseProjectItemsProps) => {
  const [loading, setLoading] = React.useState(true);
  const [hasMoreData, setHasMoreData] = React.useState(true);
  const [items, setItems] = React.useState<ProjectItem[]>([]);

  const lastKey = React.useRef<number>();

  useEffect(() => {
    setHasMoreData(true);
    lastKey.current = undefined;
  }, [query]);

  const loadBatch = React.useCallback(
    async (key?: number) => {
      console.log('ILT: In loadBatch', key, lastKey.current);

      if (!!lastKey.current && lastKey.current === key) {
        console.log('ILT: In loadBatch - Debounce');
        return Promise.resolve();
      }

      if (!key && lastKey.current) {
        console.log('ILT: In loadBatch - Already Got Data');
        return Promise.resolve();
      }

      lastKey.current = key;

      console.log('ILT: QUERY: ', JSON.stringify(query));

      if (!key) {
        try {
          try {
            const data = await getProjectWithDynamicMapping(query, LIMIT);
            const x: ProjectItem[] = data.projects.map((proj) => {
              const p = proj as ProjectItem;
              p.key = proj.creationDate;
              p.groupKey = data.lastKey;
              return p;
            });
            if (x.length < LIMIT) {
              setHasMoreData(false);
            }
            setItems(x);
          } catch (message_1) {
            return console.error(message_1);
          }
        } finally {
          return setLoading(false);
        }
      }

      try {
        try {
          const data_1 = await getProjectsForNextBatch(key, LIMIT, query);
          const x_1: ProjectItem[] = data_1.projects.map((proj_1) => {
            const p_1 = proj_1 as ProjectItem;
            p_1.key = proj_1.creationDate;
            p_1.groupKey = data_1.lastKey;
            return p_1;
          });
          if (x_1.length < LIMIT) {
            setHasMoreData(false);
          }
          setItems((i) => [...i, ...x_1]);

          // console.log('ITEMS:', JSON.stringify(x_1));
        } catch (message_1) {
          return console.error(message_1);
        }
      } finally {
        return setLoading(false);
      }
    },
    [query]
  );

  React.useEffect(() => {
    loadBatch();
  }, [loadBatch]);

  return { loading, items, loadBatch, hasMoreData };
};

export const useProject = (projectId?: string) => {
  const [project, setProject] = useState<Project>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!projectId) return;
    getProject(projectId).then((p) => {
      setProject(p);
      setLoading(false);
    });
  }, [projectId]);
  return {
    project,
    setProject,
    loading,
  };
};

export const useSuperCubeProjects = () => {
  const [superCubeProjects, setSuperCubeProjects] = useState<Project[]>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getSuperCubProjects().then((p) => {
      setSuperCubeProjects(p);
      setLoading(false);
    });
  }, []);
  return {
    superCubeProjects,
    loading,
  };
};

export const useBatchedProjects = (
  limit: number,
  lastKey: string,
  firestoreQuery: FirestoreQuery,
  ssrProjects?: Project[]
) => {
  const [projects, setProjects] = useState<Project[] | undefined>(ssrProjects);
  const [loading, setLoading] = useState(true);
  const [theLastKey, setTheLastKey] = useState('');

  useEffect(() => {
    if (lastKey.length === 0) {
      getProjectWithDynamicMapping(firestoreQuery, limit).then((rp) => {
        setProjects(rp.projects);
        setTheLastKey(rp.lastKey);
        setLoading(false);
      });
    } else {
      getProjectsForNextBatch(lastKey, limit, firestoreQuery).then((rp) => {
        setProjects(projects?.concat(rp.projects));
        setTheLastKey(rp.lastKey);
        setLoading(false);
      });
    }
  }, [limit, lastKey, firestoreQuery, projects]);
  return {
    projects,
    loading,
    theLastKey,
  };
};

export const useRelatedProjects = (projectIds: string[]) => {
  const [relatedProjects, setRelatedProjects] = useState<Project[]>();
  const [loadingRelatedProjects, setLoadingRelatedProjects] = useState(true);
  useEffect(() => {
    getRelatedProjects(projectIds).then((rp) => {
      setRelatedProjects(rp);
      setLoadingRelatedProjects(false);
    });
  }, [projectIds]);
  return {
    relatedProjects,
    loadingRelatedProjects,
  };
};

export const useCampaignProjects = (campaignShortName: string) => {
  const [campaignProjects, setCampaignProjects] = useState<Project[]>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getCollectionsForCollective(campaignShortName).then((rp) => {
      setCampaignProjects(rp);
      setLoading(false);
    });
  }, [campaignShortName]);
  return {
    campaignProjects,
    loading,
  };
};

type useProjectIdProps = {
  projectId?: string;
};

type useLTDateProps = {
  projectId?: string;
};

export const useLiveTransactionsAfterDate = (policy: string, date: number) => {
  const [liveTransactions, setLiveTransactions] = useState<LiveTransaction[]>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getLiveTransactionsAfterDate(policy).then((rp) => {
      setLiveTransactions(rp);
      setLoading(false);
    });
  }, [policy]);
  return {
    liveTransactions,
    loading,
  };
};

export const useProjectListener = ({ projectId }: useProjectIdProps) => {
  const [proj, setProj] = useState<Project>();
  const [projectsLoading, setProjectsLoading] = useState(true);
  useEffect(() => {
    if (!projectId) {
      setProjectsLoading(false);
      return;
    }
    setProjectsLoading(true);
    return getProjectListener(projectId, (data) => {
      setProj(data);
      setProjectsLoading(false);
    });
  }, [projectId]);
  return {
    proj,
    setProj,
    projectsLoading,
  };
};

export const useMintPhases = (projectId?: string) => {
  const [mintPhases, setMintPhases] = useState<MintPhase[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!projectId) return;

    console.log('In MINT PHASE FOR POLICY: ', projectId);
    getMintPhases(projectId).then((rp) => {
      setMintPhases(rp);
      setLoading(false);
    });
  }, [projectId]);
  return {
    mintPhases,
    loading,
  };
};

export const useMintPhaseDiscounts = (
  projectId: string,
  mintPhaseId: string
) => {
  const [mintPhasesDiscounts, setMintPhasesDiscounts] =
    useState<MintPhaseDiscountPolicy[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!projectId) return;

    console.log('In MINT PHASE FOR POLICY: ', projectId);
    getMintPhaseDiscounts(projectId, mintPhaseId).then((rp) => {
      setMintPhasesDiscounts(rp);
      setLoading(false);
    });
  }, [mintPhaseId, projectId]);
  return {
    mintPhasesDiscounts,
    loading,
  };
};

export const useMintPhaseAllowLists = (
  projectId?: string,
  mintPhaseId?: string
) => {
  const [allowListStakeAddress, setAllowListStakeAddress] =
    useState<LiveMintAllowStakeAddresses[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!projectId || !mintPhaseId) {
      console.log('Not valid');
      return;
    }

    console.log('In MINT PHASE FOR POLICY: ', projectId);
    getMintPhaseAllowLists(projectId, mintPhaseId).then((rp) => {
      setAllowListStakeAddress(rp);
      setLoading(false);
    });
  }, [mintPhaseId, projectId]);
  return {
    allowListStakeAddress,
    loading,
  };
};

export const useMintPhaseAllowPolicies = (projectId?: string) => {
  const [mintPhases, setMintPhases] = useState<MintPhase[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!projectId) return;

    console.log('In MINT PHASE FOR POLICY: ', projectId);
    getMintPhases(projectId).then((rp) => {
      setMintPhases(rp);
      setLoading(false);
    });
  }, [projectId]);
  return {
    mintPhases,
    loading,
  };
};

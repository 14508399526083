import { Project } from '#types/index';
import Link from 'next/link';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useInView } from 'react-intersection-observer';
import dynamic from 'next/dynamic';
import { NextImage } from '#components/Assets/NextImage';
const DSVideoPlayer = dynamic(
  () => import('#components/Assets/DSVideoPlayer'),
  {
    ssr: false,
  }
);

export interface ProjectCardPublicProps {
  project: Project;
  heightClass?: string;
  isComingSoon?: boolean;
  playAsVideo?: boolean;
  shouldPlay?: boolean;
  avoidB64?: boolean;
  path?: string;
}

dayjs.extend(relativeTime);

const renderInnerCard = (
  project: Project,
  isComingSoon: boolean,
  playAsVideo: boolean,
  shouldPlay: boolean,
  heightClass: string,
  avoidB64?: boolean,
  path?: string
) => {
  return (
    <div
      className={`relative shadow-xl group h-full w-full group-hover:-translate-y-1 rounded-xl bg-gray-50 dark:bg-gray-900 flex justify-center items-center duration-200 transform sm:hover:bg-white dark:sm:hover:bg-black cur sm:hover:shadow-xl`}
    >
      <div
        className={`w-full ${heightClass}  relative z-0 overflow-hidden rounded-xl`}
      >
        {!playAsVideo && (
          <NextImage
            src={project.heroImage}
            quality={100}
            width={600}
            b64={project.heroImageB64}
            priority={false}
            className={'object-cover w-full h-full'}
            avoidCloudflare={false}
          />
          // <ImageSwap
          //   src={project.heroImage}
          //   alt={''}
          //   thumb={project.heroImageB64}
          // />
        )}

        {project.heroImage && !!playAsVideo && project.videoCover && (
          <div className="w-full">
            <span className="z-0 absolute w-full h-full">
              <NextImage
                src={project.heroImage}
                width={100}
                quality={100}
                b64={project.heroImageB64}
                priority={false}
                layout="fill"
                className={'object-cover '}
              />
            </span>
            <span className="z-10 absolute w-full h-full">
              <DSVideoPlayer
                src={project.videoCover}
                shouldPlay={shouldPlay}
                poster={project.heroImage}
              />
            </span>
          </div>
        )}
      </div>

      <div className="w-full h-full z-10 absolute top-0  transform duration-200">
        <ProjecCardOverlay project={project} />
      </div>
    </div>
  );
};

type OverlayProps = {
  project: Project;
};
const ProjecCardOverlay = ({ project }: OverlayProps) => {
  return (
    <>
      <div className=" px-3 pb-2 h-full w-full flex flex-col rounded-xl justify-end z-10 bg-gradient-to-b from-black/0 via-black/10 to-black/50 text-shadow shadow-white/50">
        {/* <div className="flex flex-row justify-end">
            <div className="flex-col mt-4 mx-4 my-3 py-1 px-3 backdrop-filter backdrop-blur-2xl backdrop-brightness-100 border border-gray-100/20 text-gray-100 flex items-center justify-center gap-4 rounded-full">
              <div className="flex flex-row items-center gap-2 text-sm font-mono font-thin tracking-wider">
                {project.tokenPrefix}
              </div>
            </div>
          </div> */}
        <div className="absolute top-5 left-3">
          <div className="relative ml-2 rounded-lg shadow-md overflow-hidden w-32 h-32 p-2 backdrop-filter backdrop-x backdrop-brightness-200">
            <div className="relative h-28 w-28 bg-white/40 rounded-lg overflow-hidden">
              {!!project.featureImage && (
                <NextImage
                  src={project.featureImage}
                  b64={project.featureImageB64}
                  width={200}
                  height={200}
                  quality={100}
                  priority={false}
                  className={'w-full h-full rounded-lg object-cover'}
                />
              )}
            </div>
          </div>
          {(project.startDate ?? 0.0) > Date.now() && (
            <div className="max-w-[8rem] mt-4 mx-4 my-3 py-1 px-3 backdrop-filter backdrop-blur-xlx backdrop-brightness-50 border text-gray-100 flex items-center justify-center gap-4 rounded-full">
              <div className="items-center gap-2 text-sm">
                {dayjs(project.startDate).fromNow(true)}
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-white px-4 font-thin text-opacity-0 sm:group-hover:text-opacity-80 duration-200 transform max-h-36 overflow-y-hidden mb-1">
            {}
            {project.projectDescription}
          </p>

          <div className="flex flex-row  ml-2.5 -mb-3 ">
            <div className="flex flex-row text-white group gap-2  px-2 py-1 items-center text-base font-medium sm:hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 shadow-md rounded-full backdrop-filter backdrop-blurx-3xl backdrop-brightness-75">
              <div className="relative w-9 h-9 rounded-full bg-black/80">
                <NextImage
                  src={
                    !project.creator?.profilePhoto ||
                    !!(
                      project.creator?.profilePhoto &&
                      project.creator?.profilePhoto.startsWith('undefined')
                    )
                      ? project.featureImage
                      : project.creator?.profilePhoto
                  }
                  width={60}
                  height={60}
                  quality={100}
                  avoidB64={false}
                  priority={false}
                  className={'object-cover rounded-full'}
                />
              </div>

              <span className="text-sm mr-2">
                {project.creator?.displayName}
              </span>
            </div>
          </div>
          <span className="text-3xl font-bold text-white px-4 w-full  leading-10 mt-4 mb-4 flex items-center">
            {project.name}
          </span>
        </div>
      </div>
    </>
  );
};

export const ProjectCardPublic = ({
  project,
  heightClass = 'h-[40rem]',
  isComingSoon = false,
  playAsVideo = false,
  path = 'marketplace',
  avoidB64,
}: ProjectCardPublicProps) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.1,
  });

  if (isComingSoon) {
    return (
      <>
        {renderInnerCard(
          project,
          isComingSoon,
          playAsVideo,
          inView,
          heightClass,
          avoidB64,
          path
        )}
      </>
    );
  }
  return (
    <>
      <Link
        href={
          project.distribution == 'Drop'
            ? `/drops/${project.tokenPrefix}`
            : `/${path}/${project.tokenPrefix}`
        }
      >
        <a ref={ref} key={project.id} className="duration-200 transform h-full">
          <>
            {renderInnerCard(
              project,
              isComingSoon,
              playAsVideo,
              inView,
              heightClass,
              avoidB64
            )}
          </>
        </a>
      </Link>
    </>
  );
};
